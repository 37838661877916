<template>
    <div>
        <v-card class="mb-3 pa-3">
            <v-list-item three-line>
                <v-list-item-content>
                    <div class="overline mb-4">
                        <v-btn @click="$router.go(-1)"><v-icon  class="mr-3">fa fa-arrow-circle-left</v-icon> Назад</v-btn>
                        <v-btn icon color="primary" dark  class="ml-3" v-if="checkRules(course,'section','edit',course,null)"><v-icon  >fa fa-cog</v-icon></v-btn>
                        <v-btn v-if="course.creator==$auth.user().id && editMod" @click="permissionGetRules('course',course.id)" icon color="blue-grey" dark class="ml-3"><v-icon >fa fa-user</v-icon><v-badge bottom :content="course.allRules.filter(el=> el.level=='course' && el.targetId==course.id).length"></v-badge></v-btn>
                        <v-btn v-if="course.creator==$auth.user().id && editMod"  icon  color="error" dark class="ml-3"><v-icon >fa fa-trash</v-icon></v-btn>
                    </div>
                    <v-list-item-title class="headline mb-1">
                        {{course.name}}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{course.desc}}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                    tile
                    size="80"
                    v-if="!$vuetify.breakpoint.mobile"
                    ><v-icon size='80' color='primary'>mdi-book-open-variant</v-icon></v-list-item-avatar>
            </v-list-item>

        </v-card>
        <v-card class="mb-7 pa-3">
            <v-list
                subheader
                two-line
                >
                <v-list-item-group color="primary">
                    <v-list-group v-for="section in course.sections" :key="section.id">
                        <template v-slot:activator>
                            <v-list-item  style="padding-left: 0px;" >
                                <v-list-item-avatar v-if="!$vuetify.breakpoint.mobile">
                                    <v-icon

                                        class="blue-grey"
                                        dark
                                        >fa fa-book</v-icon>
                                </v-list-item-avatar>
                                                                    <v-list-item-title class=" py-2">
                                        {{section.name}} (Тем: <span v-if="section.topics">{{section.topics.length}}</span><span v-else>0</span>)
                                    </v-list-item-title>
                                    <v-list-item-subtitle v-if="section.desc">{{section.desc}}</v-list-item-subtitle>
                                <v-list-item-content >

                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn-toggle dense>
                                        <v-btn v-on:click.prevent="sectionEdit(section)" icon  v-if="checkRules(course,'section','edit',section,course)"><v-icon color="primary">fa fa-cog</v-icon></v-btn>
                                        <v-btn icon  @click.prevent="sectionTemp=section; sectionRemoveModal=true" v-if="checkRules(course,'section','delete',section,course)"><v-icon color="error">fa fa-trash</v-icon></v-btn> 
                                        <v-btn icon v-if="course.creator==$auth.user().id  && editMod" @click="permissionGetRules('section',section.id)"><v-icon>fa fa-user</v-icon><v-badge bottom :content="course.allRules.filter(el=> el.level=='section' && el.targetId==section.id).length"></v-badge></v-btn>
                                    </v-btn-toggle>
                                </v-list-item-action>
                            </v-list-item>
                        </template>



                        <v-divider inset></v-divider>
                        <v-list-item-group color="primary" >
                            <v-list-group v-for="topic in section.topics" :key="topic.id" sub-group>
                                <template v-slot:activator @click.prevent>
                                    <v-list-item  style="padding-left: 0px;" >
                                        <v-list-item-avatar>
                                            <v-icon

                                                class="blue-grey"
                                                dark
                                                >mdi-book</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content >
                                            <v-list-item-title class=" py-2">{{topic.name}}

                                            </v-list-item-title>
                                            <v-list-item-subtitle v-if="topic.desc">{{topic.desc}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-btn-toggle dense>
                                                <v-btn v-on:click.prevent.stop="topicMoveUp(section,topic)" icon  v-if="checkRules(course,'section','edit',section,course)"><v-icon>fa fa-arrow-up</v-icon></v-btn>
                                                <v-btn v-on:click.prevent.stop="topicMoveDown(section,topic)" icon  v-if="checkRules(course,'section','edit',section,course)"><v-icon>fa fa-arrow-down</v-icon></v-btn>
                                                <v-btn v-on:click.prevent.stop="topicEdit(topic)" icon   v-if="checkRules(course,'topic','edit',topic,section)"><v-icon color="primary">fa fa-cog</v-icon></v-btn>
                                                <v-btn @click.prevent.stop="topicTemp=topic; topicRemoveModal=true" icon  v-if="checkRules(course,'topic','delete',topic,section)"><v-icon color="error">fa fa-trash</v-icon></v-btn>
                                                <v-btn icon v-if="course.creator==$auth.user().id  && editMod" @click.prevent.stop="permissionGetRules('topic',topic.id)"><v-icon>fa fa-user</v-icon><v-badge bottom :content="course.allRules.filter(el=> el.level=='topic' && el.targetId==topic.id).length"></v-badge></v-btn>
                                            </v-btn-toggle>
                                        </v-list-item-action>
                                    </v-list-item>
                                </template>
                                <v-list-item :to="getLink(lesson)" v-for="lesson in topic.lessons" :key="lesson.id" style="zoom: 0.9; padding-left:110px;">
                                    <v-list-item-avatar>
                                        <v-icon v-if="lesson.done==1"
                                                x-large
                                                class="blue-grey"
                                                dark
                                                >mdi-check-circle-outline</v-icon>
                                        <v-icon  x-large
                                                 class="blue-grey"
                                                 dark v-else>mdi-checkbox-blank-circle-outline</v-icon>
                                        
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class=" py-1"><span v-if='lesson.name'>{{lesson.name}}</span>
                                            <span v-else>
                                                <span small  v-if="lesson.type=='theory'">Теоретическое занятие</span>
                                                <span  v-if="lesson.type=='practice'">Практическое занятие</span>
                                                <span  v-if="lesson.type=='self'">Самостоятельная подготовка</span>
                                                <span  v-if="lesson.type=='control'">Контроль</span>
                                            </span>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <v-chip small  v-if="lesson.type=='theory'">Теоретическое занятие</v-chip>
                                            <v-chip small  color="primary"  v-if="lesson.type=='practice'">Практическое занятие</v-chip>
                                            <v-chip small color="warning"  v-if="lesson.type=='self'">Самостоятельная подготовка</v-chip>
                                            <v-chip  small  color="error"  v-if="lesson.type=='control'">Контроль</v-chip>
                                            <v-chip  v-if="lesson.hours" small class='mx-3' outlined>Часов: {{lesson.hours}}</v-chip>
                                            <v-chip  small outlined>Ответственный: {{lesson.creator.last_name}} {{lesson.creator.name}} {{lesson.creator.second_name}}</v-chip>
                                            
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn-toggle dense>
                                            <v-btn v-on:click.prevent.stop="copyStart(lesson)" icon  v-if="checkRules(course,'lesson','edit',lesson,topic) && course.id==1"><v-icon>fa fa-copy</v-icon></v-btn>
                                            <v-btn  v-on:click.prevent.stop="editInstruction(lesson)" icon  v-if="checkRules(course,'lesson','edit',lesson,topic)"><v-icon :color="lesson.instruction?'blue':''">mdi-book-information-variant</v-icon></v-btn>
                                            <v-btn icon v-on:click.prevent="lessonEdit(lesson)" v-if="checkRules(course,'lesson','edit',lesson,topic)"><v-icon color="primary" small>fa fa-cog</v-icon></v-btn>
                                            <v-btn @click.prevent="lessonTemp=lesson; lessonRemoveModal=true" v-if="checkRules(course,'lesson','delete',lesson,topic)" icon  ><v-icon  color="error" small>fa fa-trash</v-icon></v-btn>
                                            <v-btn icon v-if="course.creator==$auth.user().id  && editMod" @click.prevent="permissionGetRules('lesson',lesson.id)"><v-icon small>fa fa-user</v-icon><v-badge bottom :content="course.allRules.filter(el=> el.level=='lesson' && el.targetId==lesson.id).length"></v-badge></v-btn>         
                                        </v-btn-toggle>
                                    </v-list-item-action>
                                </v-list-item> 
                                <v-list-item  style="zoom: 0.9; padding-left:110px;"  @click="lessonNew.topicId=topic.id;lessonNewModal=true" v-if="checkRules(course,'topic','create',topic,section)">
                                    <v-list-item-avatar>
                                        <v-icon
                                            x-large
                                            class="blue"
                                            dark
                                            >mdi-plus</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content >
                                        <v-list-item-title class=" py-2" >Добавить занятие</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-group>


                            <v-list-item  @click="topicNew.sectionId=section.id;topicNewModal=true"  v-if="checkRules(course,'section','create',section,course)"  style="zoom: 0.9; padding-left:80px;" >
                                <v-list-item-avatar>
                                    <v-icon
                                        x-large
                                        class="blue"
                                        dark
                                        >mdi-plus</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content >
                                    <v-list-item-title class=" py-2" >Добавить тему</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                        </v-list-item-group>

                    </v-list-group>
                </v-list-item-group>
            </v-list>
        </v-card>
        <v-btn color="primary"  @click="sectionNew.courseId=course.id;sectionNewModal=true"  v-if="checkRules(course,'course','create',course,null)"><v-icon class="mr-3">fa fa-plus</v-icon>Добавить раздел</v-btn>
        <v-dialog v-model="permissionModal" max-width="800">
            <v-card>
                <v-card-title class="headline">Права доступа</v-card-title>
                <v-card-text>
                    <v-row><v-col cols="10"><userSearch :value.sync="permissionNew.userId"/></v-col><v-col cols="2"><v-btn color="primary" @click='permissionAdd()'>Добавить</v-btn></v-col></v-row>
                    <v-data-table
                        :headers="permissionHeaders"
                        :items="permissionRules"
                        sort-by="user"
                        class="elevation-1"
                        >
                        <template v-slot:item.user="{ item }">
                            {{item.user.last_name}} {{item.user.name}} {{item.user.second_name}}
                        </template>
                        <template v-slot:item.edit="{ item }">
                            <v-checkbox
                                v-model="item.edit"
                                @change="permissionUpdate($event,'edit',item.id)"
                                false-value='0'
                                true-value="1"
                                ></v-checkbox>

                        </template>                       
                        <template v-slot:item.create="{ item }">
                            <v-checkbox
                                v-model="item.create"
                                @change="permissionUpdate($event,'create',item.id)"
                                false-value='0'
                                true-value="1"
                                ></v-checkbox>

                        </template>  
                        <template v-slot:item.delete="{ item }">
                            <v-checkbox
                                v-model="item.delete"
                                @change="permissionUpdate($event,'delete',item.id)"
                                false-value='0'
                                true-value="1"
                                ></v-checkbox>

                        </template>             
                        <template v-slot:item.actions="{ item }">
                            <v-icon
                                small
                                @click="permissionRemove(item.id)"
                                >
                                mdi-delete
                            </v-icon>
                        </template>
                        <template v-slot:no-data>
                            <v-btn
                                color="primary"
                                @click="initialize"
                                >
                                Reset
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="copyModal" persistent max-width="400">
            <v-card>
                <v-card-title class="headline"></v-card-title>
                <v-card-text>  {{copy.section}}
                    <v-select
                        item-text="name"
     :item-value="function(element){
     var index=copy.sections.findIndex(el=>el==element)
     return index
     }"
                        :items="copy.sections"
                        label="Раздел"
                        v-model="copy.section"
                      ></v-select>
                    <v-select v-if="copy.sections[copy.section]"
                                                      item-text="name"
                        item-value="id"
                        :items="copy.sections[copy.section].topics"
                        label="Тема"
                        v-model="copy.topic"
                      ></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="copyModal = false" v-if="!copy.busy">Отменить</v-btn>
                    <v-btn color="primary" text @click="copyExec()" v-if="!copy.busy">Сохранить</v-btn>
                    <v-btn disabled v-if="copy.busy">Идёт копирование, ожидайте...</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="sectionEditModal" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Изменить раздел</v-card-title>
                <v-card-text><v-text-field v-model="sectionTemp.name" label="Введите новое название"></v-text-field></v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="sectionEditModal = false">Отменить</v-btn>
                    <v-btn color="primary" text @click="sectionChange()">Сохранить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="sectionRemoveModal" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Вы уверены?</v-card-title>
                <v-card-text>После удаления восстановление раздела невозможно.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="sectionRemoveModal = false">Отменить</v-btn>
                    <v-btn color="error" text @click="sectionRemove()">Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="sectionNewModal" persistent max-width="540">
            <v-card>
                <v-card-title class="headline">Создать раздел</v-card-title>
                <v-card-text><v-text-field v-model="sectionNew.name" label="Введите название"></v-text-field></v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="sectionNewModal = false">Отменить</v-btn>
                    <v-btn color="primary" text @click="sectionNewSave()">Сохранить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="topicNewModal" persistent max-width="540">
            <v-card>
                <v-card-title class="headline">Создать тему</v-card-title>
                <v-card-text><v-text-field v-model="topicNew.name" label="Введите название"></v-text-field></v-card-text>
                <v-card-text><v-text-field type="number" v-model="topicNew.hours" label="Количество часов"></v-text-field></v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="topicNewModal = false">Отменить</v-btn>
                    <v-btn color="primary" text @click="topicNewSave()">Сохранить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="topicEditModal" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Изменить тему</v-card-title>
                <v-card-text><v-text-field v-model="topicTemp.name" label="Введите новое название"></v-text-field></v-card-text>
                <v-card-text><v-text-field type="number" v-model="topicTemp.hours" label="Количество часов"></v-text-field></v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="topicEditModal = false">Отменить</v-btn>
                    <v-btn color="primary" text @click="topicChange()">Сохранить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="topicRemoveModal" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Вы уверены?</v-card-title>
                <v-card-text>После удаления восстановление темы невозможно.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="topicRemoveModal = false">Отменить</v-btn>
                    <v-btn color="error" text @click="topicRemove()">Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="lessonNewModal" persistent max-width="540">
            <v-card>
                <v-card-title class="headline">Создать занятие</v-card-title>
                <v-card-text><v-text-field v-model="lessonNew.name" label="Введите название"></v-text-field></v-card-text>
                <v-card-text><v-text-field type="number" v-model="lessonNew.hours" label="Количество часов"></v-text-field></v-card-text>
                <v-card-text>      <v-select
                        v-model="lessonNew.type"
                        :items="lessonTypes"
                        label="Выберите тип занятия"
                        solo
                        ></v-select></v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="lessonNewModal = false">Отменить</v-btn>
                    <v-btn color="primary" text @click="lessonNewSave()">Сохранить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="lessonEditModal" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Изменить занятие</v-card-title>
                <v-card-text><v-text-field v-model="lessonTemp.name" label="Введите новое название"></v-text-field></v-card-text>
                <v-card-text><v-text-field type="number" v-model="lessonTemp.hours" label="Количество часов"></v-text-field></v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="lessonEditModal = false">Отменить</v-btn>
                    <v-btn color="primary" text @click="lessonChange()">Сохранить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="lessonRemoveModal" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Вы уверены?</v-card-title>
                <v-card-text>После удаления восстановление урока.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="lessonRemoveModal = false">Отменить</v-btn>
                    <v-btn color="error" text @click="lessonRemove()">Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
                <v-dialog v-model="instructionModal" persistent max-width="900">
            <v-card >
                <v-card-title class="headline">Инструктаж</v-card-title>
                <v-card-text><wysiwyg  v-model="tempInstruction" /></v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn  text @click="instructionModal = false">Отменить</v-btn>
                    <v-btn color="success" text @click="instructionSave()">Сохранить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<style>
        .editr--content{
        max-height:calc(100vh - 258px) !important;
    }
    </style>
<script>
    
    import 'array.prototype.move';
    import userSearch from '../groups/userSearch'
    export default {
        name: "index",
        data: () => ({
            copyModal:false,
            copy:{
                sections:[],
                section:0,
                topic:0,
                busy:false
            },
                page: {
                    title: "Мои курсы"
                },
                instructionModal:false,
                tempInstruction:null,
                editMod: false,
                course: {},
                sectionNewModal: false,
                sectionNew: {},
                sectionEditModal: false,
                sectionTemp: {},
                sectionRemoveModal: false,
                topicNewModal: false,
                topicNew: {},
                topicEditModal: false,
                topicTemp: {},
                topicRemoveModal: false,
                lessonNewModal: false,
                lessonNew: {},
                lessonTypes: [
                    {value: 'theory', text: "Теоретическое занятие"},
                    {value: 'practice', text: "Практическое занятие"},
                    {value: 'self', text: "Самостоятельная подготовка"},
                    {value: 'control', text: "Контроль"}
                ],
                lessonNewName: null,
                lessonEditModal: false,
                lessonTemp: {},
                lessonRemoveModal: false,
                permissionModal: false,
                permissionRules: [],
                permissionNew: {},
                permissionHeaders: [
                    {
                        text: 'Пользователь',
                        value: 'user',
                    },
                    {text: 'Изменять', value: 'edit'},
                    {text: 'Удалять', value: 'delete'},
                    {text: 'Создавать', value: 'create'},
                    {text: 'Удалить', value: 'actions', sortable: false},
                ],
            }),
        methods: {
            instructionSave(){
                    this.$http.post('lesson/instruction?id=' + this.lessonTemp.id,
                    {instruction:this.tempInstruction}).then((response) => {
                                                for (var section of this.course.sections) {
                        for (var topic of section.topics) {
                            for (var lesson of topic.lessons) {
                                if (this.lessonTemp.id == lesson.id) {
                                    lesson.instruction = response.data
                                }
                            }
                        }
                    }
                    this.instructionModal = false
                })
            },
            copyExec(){
                this.copy.busy=true
                    this.$http.get('courses/copy-exec?lessonId='+this.copy.lesson.id+'&topicId='+this.copy.topic).then(() => {
                    this.copy.busy=false
                    this.copyModal=false
                }).catch(() => {
                            this.copy.busy=false
                        })           
            },
            editInstruction(lesson){
                this.lessonTemp=lesson
                this.$http.get('lesson/instruction-get?lessonId='+lesson.id).then((response) => {
                    this.tempInstruction=response.data
                    this.instructionModal=true
                }) 
               
            },
            copyStart(lesson){
               this.copyModal=true
               this.$http.get('courses/copy-sections').then((response) => {
                    this.copy.sections=response.data
                    this.copy.lesson=lesson
                })
            },
            getLink(lesson) {
                if (this.editMod) {
                    return '/lesson/' + lesson.id + '/edit'
                }
                return '/lesson/' + lesson.timetableId
            },
            topicMoveUp(section, topic) {
                this.$http.get('topic/move-up?topicId=' + topic.id).then(() => {
                    var index = section.topics.findIndex(el => el.id == topic.id)
                    section.topics.move(index, index - 1)
                })
            },
            topicMoveDown(section, topic) {
                this.$http.get('topic/move-down?topicId=' + topic.id).then(() => {
                    var index = section.topics.findIndex(el => el.id == topic.id)
                    section.topics.move(index, index + 1)
                })

            },
            permissionAdd() {
                this.$http.post('courses/permission-add',
                        this.permissionNew).then((response) => {
                    this.permissionRules.push(response.data)
                    this.course.allRules.push(response.data)
                })
            },
            permissionRemove(id) {
                this.$http.get('courses/permission-remove?permId=' + id).then(() => {
                    let index = this.permissionRules.findIndex(el => el.id == id)
                    this.permissionRules.splice(index, 1)
                    index = this.course.allRules.findIndex(el => el.id == id)
                    this.course.allRules.splice(index, 1)
                })
            },
            permissionUpdate(action, type, id) {
                this.$http.get('courses/permission-update-rules?type=' + type + '&val=' + action + '&permId=' + id)
            },
            permissionGetRules(level, targetId) {
                this.$http.get('courses/permission-get?level=' + level + '&targetId=' + targetId).then((response) => {
                    this.permissionNew.level = level
                    this.permissionNew.targetId = targetId
                    this.permissionNew.courseId = this.course.id
                    this.permissionRules = response.data
                    this.permissionModal = true
                })
            },
            checkRules(course, level, action, target, parent) {
                if (this.editMod == false) {
                    return false
                }
                if (this.$auth.user().isAdmin) {
                    return true
                }
                if (target.creator == this.$auth.user().id) {
                    return true
                }
                if (target.creator && target.creator.user_id && target.creator.user_id == this.$auth.user().id) {
                    return true
                }
                if (course.creator == this.$auth.user().id) {
                    return true
                }
                let global = course.rules.find(el => el.level == 'course')
                if (global && global[action] == 1) {
                    return true
                }

                if (level == 'section') {
                    let sectionRules = course.rules.find(el => el.level == 'section' && el.targetId == target.id)
                    if (sectionRules && sectionRules[action] == 1) {
                        this.$set(target, action, 1)
                        return true
                    }
                }
                if (level == 'topic') {
                    if (parent[action] == 1) {
                        return true
                    }
                    let topicRules = course.rules.find(el => el.level == 'topic' && el.targetId == target.id)
                    if (topicRules && topicRules[action] == 1) {
                        this.$set(target, action, 1)
                        return true
                    }
                }
                if (level == 'lesson') {
                    if (parent[action]) {
                        return true
                    }
                    let lessonRules = course.rules.find(el => el.level == 'lesson' && el.targetId == target.id)
                    if (lessonRules && lessonRules[action] == 1) {
                        return true
                    }
                }
                return false
            },
            lessonChange() {
                
                this.$http.post('lesson/update?id=' + this.lessonTemp.id,
                        this.lessonTemp).then((response) => {
                    for (var section of this.course.sections) {
                        for (var topic of section.topics) {
                            for (var lesson of topic.lessons) {
                                if (this.lessonTemp.id == lesson.id) {
                                    lesson.name = response.data.name
                                    lesson.hours = response.data.hours
                                }
                            }
                        }
                    }
                    this.lessonTemp = {}
                    this.lessonEditModal = false
                })
            },
            lessonRemove() {
                this.$http.get('lesson/remove?id=' + this.lessonTemp.id).then(() => {
                    for (var section of this.course.sections) {
                        for (var topic of section.topics) {
                            for (var lesson of topic.lessons) {
                                if (lesson.id == this.lessonTemp.id) {
                                    topic.lessons = topic.lessons.filter(val => val !== lesson);
                                }
                            }
                        }
                    }
                    this.lessonRemoveModal = false
                })
            },
            lessonNewSave() {
                this.$http.post('lesson/add?topicId=' + this.lessonNew.topicId,
                        this.lessonNew).then(response => {
                    for (var section of this.course.sections) {
                        for (var topic of section.topics) {
                            if (this.lessonNew.topicId == topic.id) {
                                if (!topic.lessons)
                                {
                                    this.$set(topic, 'lessons', [response.data])
                                } else {
                                    topic.lessons.push(response.data)
                                }

                            }
                        }
                    }
                    this.lessonNewModal = false
                    this.lessonNew = {}
                })
            },
            topicEdit(topic) {
                this.topicTemp = JSON.parse(JSON.stringify(topic))
                this.topicEditModal = true
            },
            lessonEdit(lesson) {
                this.lessonTemp = JSON.parse(JSON.stringify(lesson))
                this.lessonEditModal = true
            },
            topicChange() {
                this.$http.post('topic/update?id=' + this.topicTemp.id,
                        this.topicTemp).then(response => {
                    for (var section of this.course.sections) {
                        for (var topic of section.topics) {
                            if (this.topicTemp.id == topic.id) {
                                topic.name = response.data.name
                                topic.hours = response.data.hours
                            }
                        }
                    }
                    this.topicTemp = {}
                    this.topicEditModal = false
                })
            },
            topicNewSave() {
                this.$http.post('topic/add?sectionId=' + this.topicNew.sectionId,
                        this.topicNew).then(response => {
                    for (var section of this.course.sections) {
                        if (this.topicNew.sectionId == section.id) {
                            if (!section.topics)
                            {
                                this.$set(section, 'topics', [response.data])
                            } else {
                                section.topics.push(response.data)
                            }
                        }
                    }
                    this.topicNewModal = false
                    this.topicNew = {}
                })
            },
            topicRemove() {
                this.$http.get('topic/remove?id=' + this.topicTemp.id).then(() => {
                    for (var section of this.course.sections) {
                        for (var topic of section.topics) {
                            if (topic.id == this.topicTemp.id) {
                                section.topics = section.topics.filter(val => val !== topic);
                            }
                        }
                    }
                    this.topicRemoveModal = false
                })
            },
            sectionEdit(section) {
                this.sectionTemp = JSON.parse(JSON.stringify(section))
                this.sectionEditModal = true
            },
            sectionChange() {
                this.$http.post('section/update?id=' + this.sectionTemp.id,
                        this.sectionTemp).then(response => {
                    for (var section of this.course.sections) {
                        if (this.sectionTemp.id == section.id) {
                            section.name = response.data.name
                        }
                    }
                    this.sectionTemp = {}
                    this.sectionEditModal = false
                })
            },
            sectionNewSave() {
                this.$http.post('section/add?courseId=' + this.sectionNew.courseId,
                        this.sectionNew).then(response => {
                    if (!this.course.sections)
                    {
                        this.$set(this.course, 'sections', [])
                    }
                    this.course.sections.push(response.data)
                    this.sectionNewModal = false
                    this.sectionNew = {}
                })
            },
            sectionRemove() {
                this.$http.get('section/remove?id=' + this.sectionTemp.id).then(() => {
                    for (var section of this.course.sections) {
                        if (section.id == this.sectionTemp.id) {
                            this.course.sections = this.course.sections.filter(val => val !== section);
                        }
                    }
                    this.sectionRemoveModal = false
                })
            },
            fetchData() {
                this.editMod = this.$route.params.mode == 'edit' ? true : false
                if (this.editMod) {
                    this.$http.get('courses/get-course',
                            {
                                params: {
                                    id: this.$route.params.id
                                }
                            }).then(response => {
                        this.course = response.data
                        this.loading = false
                    })
                } else {
                    this.$http.get('courses/course',
                            {
                                params: {
                                    id: this.$route.params.id
                                }
                            }).then(response => {
                        this.course = response.data
                        this.loading = false
                    })
                }
            }
        },
        mounted() {
            this.fetchData()
        },

        watch: {
            search() {
                // Items have already been loaded
                //if (this.permissionUsers.length > 0) return

                // Items have already been requested
                if (this.usersLoading)
                    return

                this.usersLoading = true
                this.permissionUsers = []
                // Lazily load input items
                this.$http.get('user/user-search?q=' + this.search)
                        .then(res => {
                            this.$set(this, 'permissionUsers', res.data)
                            // this.permissionUsers = res.data
                        })
                        .catch(err => {
                            console.log(err)
                        })
                        .finally(() => (this.usersLoading = false))
            },
        },
        components: {
            userSearch
        }
    };
</script>